import { Stack, styled } from '@mui/material';
import { Link } from 'react-router-dom';

// --------------------------------------------------------------

export const StyledContainer = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#222222',
  flexWrap: 'wrap',

  '@media (min-width: 750px)': {
    flexDirection: 'row',
  },
}));

export const SyledContent = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,
  height: '300px',
  minWidth: '150px',
  overflow: 'hidden',
  alignItems: 'center',
  textAlign: 'center',
  padding: '30px',
}));

export const StyledSocialMidiaIcon = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 10,
  flexWrap: 'wrap',
}));

export const StyledCopyright = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  height: '50px',
  overflow: 'hidden',
  alignItems: 'center',
  padding: '30px',
  flexWrap: 'wrap',
  alignContent: 'center',
}));

export const StyledLink = styled(Link)(() => ({
  textDecoration: 'none',
  color: 'inherit',
}));
