import { ParagraphOnPageProps } from 'src/features/@types/types';
import { StyledTypography } from './styles';

// -------------------------------------------------------------------------------------------

export default function ParagraphOnPage({ children, style, color }: ParagraphOnPageProps) {
  return (
    <StyledTypography
      variant="body1"
      align="justify"
      color={color || 'text.secondary'}
      gutterBottom
      style={style}
    >
      {children}
    </StyledTypography>
  );
}
