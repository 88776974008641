import { useEffect, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';

// ---------------------------------------------------------------------------------

export default function CookiesAdvice() {
  const [showCookieAdvice, setShowCookieAdvice] = useState(true);

  useEffect(() => {
    const isCookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (isCookiesAccepted) {
      setShowCookieAdvice(false);
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setShowCookieAdvice(false);
  };

  if (!showCookieAdvice) {
    return null;
  }

  return (
    <Stack
      style={{
        position: 'fixed',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: '#f0f0f0',
        display: 'flex',
        flexDirection: 'row',
        padding: '20px',
        gap: '20px',
        width: '80%',
        borderRadius: '20px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
        zIndex: 1000,
      }}
    >
      <Typography variant="subtitle1" align="justify">
        Este site utiliza cookies para fornecer uma melhor experiência de navegação, analisar como
        você interage com nosso site, otimizar o desempenho e personalizar o conteúdo. Ao utilizar
        este site, você concorda com o uso de cookies.
      </Typography>

      <Button variant="contained" onClick={handleAcceptCookies}>
        OK
      </Button>
    </Stack>
  );
}
