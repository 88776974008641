import { Typography } from '@mui/material';
import { TextOnPageProps } from 'src/features/@types/types';

// -------------------------------------------------------------------------------------------------

export default function TextOnPage({ title, align, mb, mt, color, sub }: TextOnPageProps) {
  return (
    <Typography
      gutterBottom
      variant={sub ? 'subtitle1' : 'h4'}
      align={align || 'center'}
      mt={mt || 6}
      mb={mb || 3}
      color={color || 'initial'}
    >
      {title}
    </Typography>
  );
}
