import { Box, LinearProgress, Stack } from '@mui/material';

// -------------------------------------------------------------

export default function CustomLoadingScreen() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Stack spacing={2} alignItems="center" sx={{ width: '80%' }}>
        <img src="/images/logo.png" width="100px" height="100px" alt="logo" />

        <LinearProgress sx={{ width: '100%' }} />
      </Stack>
    </Box>
  );
}
