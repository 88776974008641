import { Stack, styled } from '@mui/material';

// --------------------------------------------------------------------------

export const StyledContainer = styled(Stack)(() => ({
  width: '100%',
  height: 'auto',
  marginTop: '30px',
  marginBottom: '30px',
  borderRadius: '10px solid',
}));

export const StyledImage = styled('img')(() => ({
  width: '100%',
  height: 'auto',
  maxHeight: '600px',
}));
