import { Stack, styled } from '@mui/material';

// --------------------------------------------------------------

export const StyledContainer = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#222222',
  flexWrap: 'wrap',

  '@media (min-width: 600px)': {
    flexDirection: 'row',
  },
}));

export const StyledContent = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,
  height: '300px',
  minWidth: '150px',
  overflow: 'hidden',
  flexWrap: 'wrap',
  margin: '30px',
  gap: '10px',

  alignItems: 'center',
  textAlign: 'center',
  marginBottom: '60px',
}));

export const StyledSocialMidiaIcon = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 10,
  flexWrap: 'wrap',
}));

export const SyledCopyright = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  height: '50px',
  overflow: 'hidden',
  alignItems: 'center',
  flexWrap: 'wrap',
  alignContent: 'center',
}));

export const StyledCotentStack = styled(Stack)(() => ({
  alignItems: 'center',
  textAlign: 'center',
  width: '100%',
  padding: '60px',
}));

export const StyledCotentSection = styled(Stack)(() => ({
  alignItems: 'center',
  textAlign: 'center',
  width: '100%',
  padding: '60px',
}));
