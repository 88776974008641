import { Stack, Typography } from '@mui/material';
import { NewsProps } from 'src/features/@types/types';
import ParagraphOnPage from 'src/features/components/paragrathOnPage';
import TextOnPage from 'src/features/components/titlePage';
import ImageViewer from '../lightbox';
import { StyledContainer, StyledImage } from './styles';

// ----------------------------------------------------------------------------

export default function News({ children, content, disableImageViewer }: NewsProps) {
  return (
    <Stack>
      {content.map((data, index) => (
        <Stack key={index}>
          <Stack>
            <TextOnPage title={data.title} align="left" mb={1} />
            <Typography variant="subtitle2" align="left" color="text.secondary">
              <strong>Publicação: </strong> Departamento de publicidade / <strong>Curso: </strong>
              {data.course} / <strong>Data: </strong> {data.date}
            </Typography>
          </Stack>
          <StyledContainer>
            {data.cover === null && <StyledImage src={data.cover} alt="Capa Matéria" />}
          </StyledContainer>
          <Stack>
            <ParagraphOnPage style={{ padding: '0px' }} color={data.color}>
              {children}
            </ParagraphOnPage>
          </Stack>

          {!disableImageViewer && (
            <Stack marginTop={5} marginBottom={2}>
              <ImageViewer
                folder={data.folder}
                length={data.lenghtPic}
                label={data.title}
                ext={data.ext}
              />
            </Stack>
          )}
        </Stack>
      ))}
    </Stack>
  );
}
