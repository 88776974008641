import { Box, BoxProps, Link } from '@mui/material';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const logo = (
      <Box
        component="img"
        src="/images/logo-letter.png"
        sx={{ width: 250, height: 100, cursor: 'pointer', ...sx }}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
        {logo}

        {/* <Typography variant="h4" ml={1} style={{ fontWeight: 'bold', color: '#7C2B92' }}>
          FASA
        </Typography> */}
      </Link>
    );
  }
);

export default Logo;
