import { Stack, styled } from '@mui/material';
import { Link } from 'react-router-dom';

// --------------------------------------------------------------------------

export const StyledHeaderTop = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  height: '40px',
  overflowX: 'hidden',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
}));

export const StyledContact = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 10,
  alignContent: 'center',
}));

export const StyledLink = styled(Link)(() => ({
  textDecoration: 'none',
  color: 'inherit',
}));
