// function path(root: string, sublink: string) {
//   return `${root}${sublink}`;
// }

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
export const ROOTS_NEWS = '/noticias-e-eventos';

const path = (root: string, subPath: string) => `${root.replace(/\/$/, '')}${subPath}`;

// *************************************** PADRAO *********************************

export const PATH_PAGE = {
  comingSoon: '/embreve',
  catalogo: '/catalogo',
  toldosgaragem: '/toldosgaragem',

  // =============================================================================

  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',

  // =============================================================================
};

export const PATH_COURSES = {
  enfermagem: '/enfermagem',
  direito: '/direito',
  biomedicina: '/biomedicina',
  pedagogia: '/pedagogia',
  psicologia: '/psicologia',
  frontend: '/frontend',
};

export const PATH_CONTACT = {
  phone: 'https://api.whatsapp.com/send/?phone=5569981180306&text&type=phone_number&app_absent=0',
  justNumber: '5569981180306',
  whatsApp: 'https://wa.me/5569981180306',
};

export const PATH_POSTGRAUDATION = {
  main: '/pos-graduacao',
  saudeseguranca: '/pos-graduacao/saude-seguranca-do-trabalho',
  saudefamilia: '/pos-graduacao/políticas-publicas-e-programa-de-saude-da-família',
  direitoTrabalho: '/pos-graduacao/direito-do-trabalho',
};

export const PATH_VESTIBULAR = {
  main: '/vestibular',
  inscricao: 'vestibular/inscricao',
};

export const PATH_PORTAL_ACADEMICO = {
  cbk: 'https://fasa.cbk.com.br/login',
};

export const PATH_EMPRESAS = {
  fullcode: 'https://www.fullcodedev.com/',
};

export const PATH_NEWS = {
  root: ROOTS_NEWS,
  festaJunina: path(ROOTS_NEWS, '/festa-junina'),
  vadeMecum: path(ROOTS_NEWS, '/vade-mecum'),
  citologia: path(ROOTS_NEWS, '/aula-pratica-citologia'),
  bioquimica: path(ROOTS_NEWS, '/aula-pratica-bioquimica'),
  jaleco: path(ROOTS_NEWS, '/cerimonia-do-jaleco'),
  intramuscular: path(ROOTS_NEWS, '/administracao-intramuscular'),
  vacinacao: path(ROOTS_NEWS, '/campanha-vacinacao-oab-ro'),
  tribunal: path(ROOTS_NEWS, '/tribunal-simulado'),
  interacao: path(ROOTS_NEWS, '/interacao-cominitaria'),
  npj: path(ROOTS_NEWS, '/npj'),
  outubroRosa: path(ROOTS_NEWS, '/outubro-rosa'),
  saudeEscola: path(ROOTS_NEWS, '/projeto-saude-na-escola'),
};

// *************************************** FIM PADRAO *****************************

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    file: path(ROOTS_DASHBOARD, '/file'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
