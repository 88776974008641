import { Link } from 'react-router-dom';
import Iconify from 'src/components/iconify';
import { Props } from 'src/features/@types/customIconify';


// -----------------------------------------------------------------------

export default function CustomIconify({ href, icon, color, style }: Props) {
  return (
    <Link to={href} target="_blank" rel="noopener noreferrer">
      <Iconify icon={icon} width="30px" color={color} style={style} />
    </Link>
  );
}
