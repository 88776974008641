import { Navigate, useRoutes } from 'react-router-dom';
import { CustomRouterProps } from 'src/features/@types/types';
import AulaCitologia from 'src/features/pages/noticias/aulaCitologia';
import CompactLayout from '../layouts/compact';
import MainLayout from '../layouts/main';
import {
  AboutUs,
  AulaBioquimica,
  BibliotecaPage,
  CerimoniaJaleco,
  ComingSoonPage,
  ContactUs,
  CpaPage,
  CursoDireito,
  CursoEnfermagem,
  CursoPedagogia,
  DireitoTrabalho,
  Documentos,
  FestaJunina,
  HomePage,
  Impensa,
  InscricaoVestibular,
  InteracaoComunitaria,
  Intramuscular,
  MaintenancePage,
  NPJEvento,
  NpjPage,
  OutubroRosa,
  Page404,
  PosGraudate,
  SaudeEscola,
  SaudeFamilia,
  SaudeSeguranca,
  Tribunal,
  Vacinacao,
  VadeMecum,
  Vestibular,
} from './elements';
import { PATH_NEWS } from './paths';

// -------------------------------------------------------------------------------

export default function Router({ isMobile }: CustomRouterProps) {
  return useRoutes([
    {
      element: <MainLayout />,
      children: [
        { element: <HomePage isSmallScreen={isMobile} />, index: true },
        {
          path: 'direito',
          element: <CursoDireito />,
        },
        {
          path: 'enfermagem',
          element: <CursoEnfermagem />,
        },
        {
          path: 'pedagogia',
          element: <CursoPedagogia />,
        },
        {
          path: 'pos-graduacao',
          element: <PosGraudate />,
        },
        {
          path: 'pos-graduacao/saude-seguranca-do-trabalho',
          element: <SaudeSeguranca />,
        },
        {
          path: 'pos-graduacao/políticas-publicas-e-programa-de-saude-da-família',
          element: <SaudeFamilia />,
        },
        {
          path: 'pos-graduacao/direito-do-trabalho',
          element: <DireitoTrabalho />,
        },
        {
          path: 'vestibular',
          element: <Vestibular />,
        },
        {
          path: 'vestibular/inscricao',
          element: <InscricaoVestibular />,
        },
        {
          path: 'quem-somos',
          element: <AboutUs />,
        },
        {
          path: 'documentos-institucionais',
          element: <Documentos />,
        },
        {
          path: 'fale-conosco',
          element: <ContactUs />,
        },

        {
          path: 'cpa',
          element: <CpaPage />,
        },
        {
          path: 'biblioteca',
          element: <BibliotecaPage />,
        },
        {
          path: 'npj',
          element: <NpjPage />,
        },
        {
          path: PATH_NEWS.root,
          element: <Impensa />,
        },
        {
          path: PATH_NEWS.festaJunina,
          element: <FestaJunina />,
        },
        {
          path: PATH_NEWS.vadeMecum,
          element: <VadeMecum />,
        },
        {
          path: PATH_NEWS.citologia,
          element: <AulaCitologia />,
        },
        {
          path: PATH_NEWS.bioquimica,
          element: <AulaBioquimica />,
        },
        {
          path: PATH_NEWS.jaleco,
          element: <CerimoniaJaleco />,
        },
        {
          path: PATH_NEWS.intramuscular,
          element: <Intramuscular />,
        },
        {
          path: PATH_NEWS.vacinacao,
          element: <Vacinacao />,
        },
        {
          path: PATH_NEWS.tribunal,
          element: <Tribunal />,
        },
        {
          path: PATH_NEWS.interacao,
          element: <InteracaoComunitaria />,
        },
        {
          path: PATH_NEWS.npj,
          element: <NPJEvento />,
        },
        {
          path: PATH_NEWS.outubroRosa,
          element: <OutubroRosa />,
        },
        {
          path: PATH_NEWS.saudeEscola,
          element: <SaudeEscola />,
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'embreve', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '404', element: <Page404 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
