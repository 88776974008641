import { Stack, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import { Animate } from 'src/features/global/styles';
import { PATH_CONTACT, PATH_PORTAL_ACADEMICO } from 'src/routes/paths';
import { StyledContact, StyledHeaderTop, StyledLink } from './styles';

// ----------------------------------------------------------------------------

export default function HeaderTop() {
  return (
    <StyledHeaderTop>
      <StyledLink to={PATH_CONTACT.whatsApp} rel="noopener noreferrer">
        <Animate>
          <StyledContact>
            <Iconify icon="logos:whatsapp-icon" />
            <Typography gutterBottom variant="caption" color="white">
              Contato WhatsApp: (69) 98118-0306
            </Typography>
          </StyledContact>
        </Animate>
      </StyledLink>

      <StyledLink to={PATH_PORTAL_ACADEMICO.cbk} rel="noopener noreferrer">
        <Stack display="flex" gap={3} flexDirection="row">
          <Animate>
            <StyledContact>
              <Iconify icon="tabler:external-link" color="white" />
              <Typography gutterBottom variant="caption" color="white">
                Portal Acadêmico
              </Typography>
            </StyledContact>
          </Animate>
        </Stack>
      </StyledLink>
    </StyledHeaderTop>
  );
}
