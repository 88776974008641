import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { PATH_EMPRESAS } from 'src/routes/paths';
import { COLOR } from 'src/theme/color';
import CustomIconify from '../components/customIconify';
import { emecPath } from '../global/links';
import { StyledContainer, StyledContent, StyledCotentStack, StyledSocialMidiaIcon } from './styles';

// ---------------------------------------------------------------------------

export default function FooterMobile() {
  return (
    <StyledContainer>
      <StyledCotentStack>
        <Link to={emecPath} rel="noopener noreferrer">
          <img src="images/footer/qr-emec.png" height={150} alt="E-MEC QRCODE" />
        </Link>
      </StyledCotentStack>

      {/* ======================================================================================================= */}

      <StyledContent>
        <Typography gutterBottom variant="h5" component="div" align="center" color={COLOR.white}>
          Redes Sociais
        </Typography>

        <StyledSocialMidiaIcon>
          <CustomIconify icon="logos:facebook" href="https://www.facebook.com/fasavilhena" />

          <CustomIconify
            icon="skill-icons:instagram"
            href="https://www.instagram.com/fasa.vilhena.oficial/"
          />

          <CustomIconify
            icon="logos:youtube-icon"
            href="https://www.youtube.com/@fasa-faculdadesantoandrema8265"
          />
        </StyledSocialMidiaIcon>
      </StyledContent>

      {/* ======================================================================================================= */}

      <StyledCotentStack>
        <Typography gutterBottom variant="h5" component="div" align="center" color={COLOR.white}>
          Endereço
        </Typography>

        <Typography gutterBottom variant="body1" component="div" align="center" color={COLOR.white}>
          Av. Aníbal Ribeiro Batista, Nº 4077 <br /> Res. Orleans, CEP: 76985-784 - Vilhena - RO
        </Typography>
      </StyledCotentStack>
      {/* ======================================================================================================= */}

      <StyledCotentStack>
        <Typography gutterBottom variant="h5" component="div" align="center" color={COLOR.white}>
          Contato
        </Typography>

        <Typography gutterBottom variant="body1" component="div" align="center" color={COLOR.white}>
          Telefone: (69) 98118-0306 <br />
          E-mail: contato@fasaro.edu.br
        </Typography>
      </StyledCotentStack>

      {/* ======================================================================================================= */}

      <StyledContent>
        <Typography gutterBottom variant="h5" component="div" align="center" color={COLOR.white}>
          Copyright 2024 - Faculdade Santo André Todos os direitos reservados
        </Typography>

        <Typography gutterBottom variant="h5" component="div" align="center" color={COLOR.white}>
          Desenvolvido por: Full Code Software e Tecnologia
        </Typography>
      </StyledContent>

      {/* ======================================================================================================= */}

      <StyledContent>
        <Link to={PATH_EMPRESAS.fullcode}>
          <img
            src="/images/logo-desenvolvedor.png"
            width={200}
            height={60}
            alt="E-MEC QRCODE"
            style={{ borderRadius: '10px' }}
          />
        </Link>
      </StyledContent>
    </StyledContainer>
  );
}
