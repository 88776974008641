export const localizationIFrame: string =
  'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3891.8091578885465!2d-60.10192222417303!3d-12.725869654918187!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x93b9696c559f91e9%3A0xe417237622cf2f7f!2sFASA%20-%20Faculdade%20Santo%20Andr%C3%A9%20-%20Vilhena%2FRo!5e0!3m2!1spt-BR!2sus!4v1713902669180!5m2!1spt-BR!2sus';

export const localizationAddress: string =
  'https://www.google.com/maps/dir//FASA+-+Faculdade+Santo+Andr%C3%A9+-+Vilhena%2FRo+-+Av.+An%C3%ADbal+Ribeiro+Batista,+4077+-+Res.+Orleans,+Vilhena+-+RO,+76980-000,+Brasil/@-12.725788,-60.1405472,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x93b9696c559f91e9:0xe417237622cf2f7f!2m2!1d-60.0993473!2d-12.7258749?hl=pt-BR&entry=ttu';

export const emecPath: string =
  'https://emec.mec.gov.br/emec/consulta-cadastro/detalhamento/d96957f455f6405d14c6542552b0f6eb/MTc1NTg=?fbclid=IwAR3VRcpwT2HMq76wYEGJCJCaCcWIKT0TUqzDZh6yXmqrtEkuovp-ZB4WQlc';

export const mecImageHome: string =
  'https://i0.wp.com/www.unibrasil.com.br/wp-content/uploads/2019/06/1de772f6-fa2e-4493-a103-468812c64c70.jpeg?fit=943%2C629&ssl=1';
